
'use client'
import React, { createContext, useContext, useState, useCallback } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filtersState, setFiltersState] = useState({});

  const updateFilters = useCallback((tabId, newFilters) => {
    setFiltersState((prevState) => ({
      ...prevState,
      [tabId]: newFilters,
    }));
  }, []);

  return (
    <FilterContext.Provider value={{ filtersState, updateFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FilterContext);
};
