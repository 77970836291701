import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";




export const manageAccountGetAllAction = createAsyncThunk(
  "manageUser/getAll",
  async ({ page_no, limit }, thunkAPI) => {
    try {
      const response = await axiosInstance.post( `/v1/auth/get-user-details`,{ page_no, limit},
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        },
        // console.log(JSON.parse(Cookies.get("token")))
      );

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const manageuserdeatails = createAsyncThunk(
  "manageUser/getUserDetails",
  async (user_id, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/v1/auth/user-details', { user_id },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const Createuserdeatails = createAsyncThunk(
  "v1/auth/create-user-details",
  async (FormdataSubmit, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/auth/create-user-details',FormdataSubmit,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
              "Content-Type": "multipart/form-data",
          },
        
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const Deleteuserdeatails = createAsyncThunk(
  "v1/auth/delete-user",
  async ({user_id, status }, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/v1/auth/delete-user',{user_id, status },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const GetProviderOption = createAsyncThunk(
  "v1/provider-identifier-data",
  async ({user_id }, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/provider-identifier-data',{user_id},
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);