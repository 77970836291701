import { createSlice } from "@reduxjs/toolkit";
import { GetAllChennal,ChennalEditAction } from "./ManageChannelAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getAllChannel: getInitialStatus(),
  EditChannel: getInitialStatus(),
  ChannelData: [],
  FeedProviderOptions:[],
  TeamMemberOptions:[],
  totalCount: 0,
};

const ManageChannelSlice = createSlice({
  name: "ManageChannel",
  initialState,
  reducers: {
    resetChennal: (state) => {
      state.getAllChannel = getInitialStatus();
      state.EditChannel = getInitialStatus();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllChennal.pending, (state) => {
      state.getAllChannel = getInitialStatus();
      state.getAllChannel.isLoading = true;
    });
    builder.addCase(GetAllChennal.rejected, (state, action) => {
      state.getAllChannel = getInitialStatus();
      state.getAllChannel.isError = true;
      const { response, message } = action.payload || {};
      state.getAllChannel.errorResponse = message;
      console.log('action.payload',action.payload);
    });
    builder.addCase(GetAllChennal.fulfilled, (state, action) => {
      state.getAllChannel = getInitialStatus();
      state.getAllChannel.isSuccess = true;
      state.ChannelData = action.payload.data || [];
      state.FeedProviderOptions = action.payload.feed_provider || [];
      state.TeamMemberOptions = action.payload.team_member || [];
      state.totalCount = action.payload.total_count?.count || 0; 
      state.getAllChannel.successMsg = "Channels fetched successfully"; 
    });
    // 

    builder.addCase(ChennalEditAction.pending, (state) => {
      state.EditChannel = getInitialStatus();
      state.EditChannel.isLoading = true;
    });
    builder.addCase(ChennalEditAction.rejected, (state, action) => {
      state.EditChannel = getInitialStatus();
      state.EditChannel.isError = true;
      const { response, message } = action.payload || {};
       state.EditChannel.errorMsg = response.data.message;
  
    
      // console.log('message',message);
    });
    builder.addCase(ChennalEditAction.fulfilled, (state, action) => {
      state.EditChannel = getInitialStatus();
      state.EditChannel.isSuccess = true;
      state.getAllChannel.successMsg = "Channels fetched successfully"; 
    });
  },
});

export const { resetChennal } = ManageChannelSlice.actions;

export default ManageChannelSlice.reducer;
