//   v1/summary-report

import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetSummaryReportFiltersAction = createAsyncThunk(
  "v1/get-summary-custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/get-summary-custom-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);


export const UpdateSummaryCustomReportAction = createAsyncThunk(
  "v1/summary-custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/summary-custom-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const GetCustomSummaryReportAction = createAsyncThunk(
  "v1/summary-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/summary-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const GetHourlySummaryReportAction = createAsyncThunk(
  "v1/daywise-summary-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/daywise-summary-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const ExportSummaryReportAction = createAsyncThunk(
  "v1/summary-data-excel-csv",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/summary-data-excel-csv',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);