import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetAllChennal = createAsyncThunk(
  "v1/ci/get-identifier",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/ci/get-identifier", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// v1/ci/upsert-identifier
export const ChennalEditAction = createAsyncThunk(
  "v1/ci/upsert-identifier",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/ci/upsert-identifier", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// v1/ci/delete-identifier
export const ChennalDeleteAction = createAsyncThunk(
  "v1/ci/delete-identifier",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/ci/delete-identifier", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);