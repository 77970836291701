import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetAllManageSites = createAsyncThunk(
  "v1/managesite",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/v1/ms/get-sites", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const AddNewSite = createAsyncThunk(
    "v1/Addsite",
    async (formdata, thunkAPI) => {
      try {
        const response = await axiosInstance.post(
          "/v1/ms/upsert-sites",
          formdata,
          {
            headers: {
              Authorization: Cookies.get("token")
                ? `${JSON.parse(Cookies.get("token"))}`
                : "",
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const data = response?.data;
          return data;
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data || error.message);
      }
    }
  );
  


  export const UpdateSiteAction = createAsyncThunk(
    "v1/UpdateSite",
    async (req, thunkAPI) => {
      try {
        const response = await axiosInstance.post(
          "/v1/ms/upsert-sites",
          req,
          {
            headers: {
              Authorization: Cookies.get("token")
                ? `${JSON.parse(Cookies.get("token"))}`
                : "",
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const data = response?.data;
          return data;
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data || error.message);
      }
    }
  );


  export const DeleteSiteAction = createAsyncThunk(
  "v1/ms/delete-sites",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/ms/delete-sites",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);