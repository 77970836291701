import { createSlice } from "@reduxjs/toolkit";
import {
  GetDashboardDetailsAction,
  GetVolumeReportDetailsAction,
  GetImpressionTrendsReportAction,
  GetRpmTrendsReportAction,
  GetWinnerLooserReportAction,
  GetRpCTrendsReportAction,
  GetCpCTrendsReportAction,
  GetCampaignCountAction,
  GetAccountWiseDataAction,
} from "./DashboardAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  DashboardDeatails: getInitialStatus(),
  VolumeReportDetails: getInitialStatus(),
  IMpressionTrendDetails: getInitialStatus(),
  RpmTrendDetails: getInitialStatus(),
  GetwinLoseData: getInitialStatus(),
  RPCTrendDetails: getInitialStatus(),
  CPCTrendDetails: getInitialStatus(),
  CampaignCounts: getInitialStatus(),
  AccountWiseDatas: getInitialStatus(),
  Dashdata: {},
  Tabledata: {},
  WinnerData: {},
  LooserData: {},
  RPCData: {},
  CPCData: {},
  CampaignCountsData:{},
  AccountWiseApiData:{},
};

const DashboardSlice = createSlice({
  name: "GetDashboard",
  initialState,
  reducers: {
    UserDashboardResetvalue: (state) => {
      state.DashboardDeatails = getInitialStatus();
      state.VolumeReportDetails = getInitialStatus();
      state.IMpressionTrendDetails = getInitialStatus();
      state.RpmTrendDetails = getInitialStatus();
    },
    UserDashboardResetvalueData: (state) => {
      state.DashboardDeatails = getInitialStatus();
      state.VolumeReportDetails = getInitialStatus();
      state.IMpressionTrendDetails = getInitialStatus();
      state.RpmTrendDetails = getInitialStatus();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetDashboardDetailsAction.pending, (state) => {
      state.DashboardDeatails.isLoading = true;
    });
    builder.addCase(GetDashboardDetailsAction.fulfilled, (state, action) => {
      state.DashboardDeatails.isLoading = false;
      state.DashboardDeatails.isSuccess = true;
      state.Tabledata = action.payload;
    });
    builder.addCase(GetDashboardDetailsAction.rejected, (state, action) => {
      state.DashboardDeatails.isLoading = false;
      state.DashboardDeatails.isError = true;
      state.DashboardDeatails.errorMsg = action.payload?.response;
      state.DashboardDeatails.errorResponse = action.payload;
    });
    // volume report
    builder.addCase(GetVolumeReportDetailsAction.pending, (state) => {
      state.VolumeReportDetails.isLoading = true;
    });
    builder.addCase(GetVolumeReportDetailsAction.fulfilled, (state, action) => {
      state.VolumeReportDetails.isLoading = false;
      state.VolumeReportDetails.isSuccess = true;
      state.Dashdata = action.payload;
    });
    builder.addCase(GetVolumeReportDetailsAction.rejected, (state, action) => {
      state.VolumeReportDetails.isLoading = false;
      state.VolumeReportDetails.isError = true;
      state.VolumeReportDetails.errorMsg = action.payload?.response;
      state.VolumeReportDetails.errorResponse = action.payload;
    });
    // impression trends
    builder.addCase(GetImpressionTrendsReportAction.pending, (state) => {
      state.IMpressionTrendDetails.isLoading = true;
    });
    builder.addCase(
      GetImpressionTrendsReportAction.fulfilled,
      (state, action) => {
        state.IMpressionTrendDetails.isLoading = false;
        state.IMpressionTrendDetails.isSuccess = true;
        state.Dashdata = action.payload;
      }
    );
    builder.addCase(
      GetImpressionTrendsReportAction.rejected,
      (state, action) => {
        state.IMpressionTrendDetails.isLoading = false;
        state.IMpressionTrendDetails.isError = true;
        state.IMpressionTrendDetails.errorMsg = action.payload?.response;
        state.IMpressionTrendDetails.errorResponse = action.payload;
      }
    );
    // rpm-trends
    builder.addCase(GetRpmTrendsReportAction.pending, (state) => {
      state.RpmTrendDetails.isLoading = true;
    });
    builder.addCase(GetRpmTrendsReportAction.fulfilled, (state, action) => {
      state.RpmTrendDetails.isLoading = false;
      state.RpmTrendDetails.isSuccess = true;
      state.Dashdata = action.payload;
    });
    builder.addCase(GetRpmTrendsReportAction.rejected, (state, action) => {
      state.RpmTrendDetails.isLoading = false;
      state.RpmTrendDetails.isError = true;
      state.RpmTrendDetails.errorMsg = action.payload?.response;
      state.RpmTrendDetails.errorResponse = action.payload;
    });

    // rpc-trends
    builder.addCase(GetRpCTrendsReportAction.pending, (state) => {
      state.RPCTrendDetails.isLoading = true;
    });
    builder.addCase(GetRpCTrendsReportAction.fulfilled, (state, action) => {
      state.RPCTrendDetails.isLoading = false;
      state.RPCTrendDetails.isSuccess = true;
      state.RPCData = action.payload;
    });
    builder.addCase(GetRpCTrendsReportAction.rejected, (state, action) => {
      state.RPCTrendDetails.isLoading = false;
      state.RPCTrendDetails.isError = true;
      state.RPCTrendDetails.errorMsg = action.payload?.response;
      state.RPCTrendDetails.errorResponse = action.payload;
    });
    // Cpc-trends
    builder.addCase(GetCpCTrendsReportAction.pending, (state) => {
      state.CPCTrendDetails.isLoading = true;
    });
    builder.addCase(GetCpCTrendsReportAction.fulfilled, (state, action) => {
      state.CPCTrendDetails.isLoading = false;
      state.CPCTrendDetails.isSuccess = true;
      state.CPCData = action.payload;
    });
    builder.addCase(GetCpCTrendsReportAction.rejected, (state, action) => {
      state.CPCTrendDetails.isLoading = false;
      state.CPCTrendDetails.isError = true;
      state.CPCTrendDetails.errorMsg = action.payload?.response;
      state.CPCTrendDetails.errorResponse = action.payload;
    });

    // WinnerLooserData
    builder.addCase(GetWinnerLooserReportAction.pending, (state) => {
      state.GetwinLoseData.isLoading = true;
    });
    builder.addCase(GetWinnerLooserReportAction.fulfilled, (state, action) => {
      state.GetwinLoseData.isLoading = false;
      state.GetwinLoseData.isSuccess = true;
      state.WinnerData = action.payload?.winner;
      state.LooserData = action.payload?.loosers;
      // console.log(action.payload);
    });
    builder.addCase(GetWinnerLooserReportAction.rejected, (state, action) => {
      state.GetwinLoseData.isLoading = false;
      state.GetwinLoseData.isError = true;
      state.GetwinLoseData.errorMsg = action.payload?.response;
      state.GetwinLoseData.errorResponse = action.payload;
    });
    // Campaign counts
    builder.addCase(GetCampaignCountAction.pending, (state) => {
      state.CampaignCounts.isLoading = true;
    });
    builder.addCase(GetCampaignCountAction.fulfilled, (state, action) => {
      state.CampaignCounts.isLoading = false;
      state.CampaignCounts.isSuccess = true;
      state.CampaignCountsData = action.payload;
    });
    builder.addCase(GetCampaignCountAction.rejected, (state, action) => {
      state.CampaignCounts.isLoading = false;
      state.CampaignCounts.isError = true;
      state.CampaignCounts.errorMsg = action.payload?.response;
      state.CampaignCounts.errorResponse = action.payload;
    });
    // GetAccountWiseDataAction

    builder.addCase(GetAccountWiseDataAction.pending, (state) => {
      state.AccountWiseDatas.isLoading = true;
    });
    builder.addCase(GetAccountWiseDataAction.fulfilled, (state, action) => {
      state.AccountWiseDatas.isLoading = false;
      state.AccountWiseDatas.isSuccess = true;
      state.AccountWiseApiData = action.payload;
    });
    builder.addCase(GetAccountWiseDataAction.rejected, (state, action) => {
      state.AccountWiseDatas.isLoading = false;
      state.AccountWiseDatas.isError = true;
      state.AccountWiseDatas.errorMsg = action.payload?.response;
      state.AccountWiseDatas.errorResponse = action.payload;
    });
  },
});

export const { UserDashboardResetvalue, UserDashboardResetvalueData } =
  DashboardSlice.actions;
export default DashboardSlice.reducer;
