import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie"; // Ensure Cookies import

export const ManageGetproviderAction = createAsyncThunk(
  "manageUser/getAll",
  async ({ page_no, limit }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/fp/get-provider",
        { page_no, limit },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      } 
    } catch (error) {
      console.error("API call failed:", error);
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const ManageAddProviderAction = createAsyncThunk(
  "settings/add-provider",
  async (formdata, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/fp/upsert-provider",
        formdata,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


export const ManageDeleteProviderAction = createAsyncThunk(
  "settings/delete-provider",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/fp/delete-provider",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);