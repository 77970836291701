import { createSlice } from "@reduxjs/toolkit";
import { GetAllManageSites,AddNewSite ,DeleteSiteAction} from "./ManageSitesAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getManageSites: getInitialStatus(),
  newSite:getInitialStatus(),
  DeleteSite:getInitialStatus(),
  sitesData: [],
  totalCount: 0,
};

const ManageSiteslice = createSlice({
  name: "manageSites",
  initialState,
  reducers: {
    resetSite: (state) => {
      state.getManageSites = getInitialStatus();
      state.newSite = getInitialStatus();
      state.DeleteSite = getInitialStatus();
    },
    resetSitesValue: (state) => {
      state.getManageSites = getInitialStatus();
      state.newSite = getInitialStatus();
      state.DeleteSite = getInitialStatus();
      state.totalCount = 0;
      state.sitesData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllManageSites.pending, (state) => {
      state.getManageSites = getInitialStatus();
      state.getManageSites.isLoading = true;
    });
    builder.addCase(GetAllManageSites.rejected, (state, action) => {
      state.getManageSites = getInitialStatus();
      state.getManageSites.isError = true;
      state.getManageSites.errorResponse = action.payload;
    });
    builder.addCase(GetAllManageSites.fulfilled, (state, action) => {
      state.getManageSites = getInitialStatus();
      state.getManageSites.isSuccess = true;
      const { total_count, response, message } = action.payload || {};
      state.getManageSites.successMsg = message;
      state.sitesData = response || [];
      state.totalCount = total_count[0].count || 0;
    });

    // add new site
    builder.addCase(AddNewSite.pending, (state) => {
        state.newSite = getInitialStatus();
        state.newSite.isLoading = true;
      });
      builder.addCase(AddNewSite.rejected, (state, action) => {
        state.newSite = getInitialStatus();
        state.newSite.isError = true;
        state.newSite.errorResponse = action.payload;
      });
      builder.addCase(AddNewSite.fulfilled, (state, action) => {
        state.newSite = getInitialStatus();
        state.newSite.isSuccess = true;
        const { response, message } = action.payload || {};
        state.newSite.successMsg = message;
        state.sitesData = [...state.sitesData, response];
      });
       // Delete site
    builder.addCase(DeleteSiteAction.pending, (state) => {
        state.DeleteSite = getInitialStatus();
        state.DeleteSite.isLoading = true;
      });
      builder.addCase(DeleteSiteAction.rejected, (state, action) => {
        state.DeleteSite = getInitialStatus();
        state.DeleteSite.isError = true;
        state.DeleteSite.errorResponse = action.payload;
      });
      builder.addCase(DeleteSiteAction.fulfilled, (state, action) => {
        state.DeleteSite = getInitialStatus();
        state.DeleteSite.isSuccess = true;
        const { response, message } = action.payload || {};
        state.DeleteSite.successMsg = message;
        state.DeleteSite.errorResponse=response
      });
  },
});

export const {resetSite,resetSitesValue} =ManageSiteslice.actions

export default ManageSiteslice.reducer