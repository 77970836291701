// import { createSlice } from "@reduxjs/toolkit";
// import { GetLiveReportFiltersAction,
//   UpdateLiveCustomReportAction,
//   GetLiveReportAction,
//   GetHourlyLiveReportAction } from "./FilterAction";
// import { format } from "date-fns";





// const getInitialStatus = () => ({
//   isLoading: false,
//   isError: false,
//   isSuccess: false,
//   errorMsg: null,
//   errorResponse: null,
//   successMsg: null,
// });


// const getCurrentDateRange = () => {
//   const yesterday = new Date();
//   yesterday.setDate(yesterday.getDate() - 1);
//   const formattedYesterday = format(yesterday, "yyyy-MM-dd");
//   return { from_date: formattedYesterday, to_date: formattedYesterday };
// };

// const getInitialTabState = () => ({
//   duration: { date: true, month: false },
//   indicator: {
//     FeedProvider: true,
//     ad_creatives: true,
//     adset: true,
//     ad: true,
//     media_buyer: true,
//     impression: true,
//     clicks: true,
//   },
//   calculation: {
//     rpc: true,
//     rpm: true,
//     spend: true,
//     revenue: true,
//     cpc: true,
//     margin: true,
//     margin_percent: true,
//     roas: true,
//   },
//   filter: {
//     feed_provider: true,
//     media_buyer: true,
//     channel: true,
//     channel_2: true,
//     channel_3: true,
//   },
//   filterOptions: {
//     feed_provider: [],
//     media_buyer: [],
//     channel: [],
//     channel_2: [],
//     channel_3: [],
//   },
//   selectedOptions: {
//     channel: "",
//     channel_2: "",
//     channel_3: "",
//     feed_provider: "",
//     media_buyer: "",
//   },
//   date_range: getCurrentDateRange(),
// });


//   const formatOpChennal3 = (options) =>
//     options.map((option) => ({
//       value: option.identifier_name,
//      label: option.identifier_name ,
//     }));

//     const formatOpFeed = (options) =>
//     options.map((option) => ({
//       value: option.feed_provider_name,
//      label: option.feed_provider_name ,
//     }));

//     const formatOpMedibuyer = (options) =>
//     options.map((option) => ({
//       value: option.user_id,
//      label: option.name ,
//     }));



// const initialState = {
//   Getfillterdata: getInitialStatus(),
//   GetCustomLivedata: getInitialStatus(),
//   GetHourlyLivedata:getInitialStatus(),
//   GetExportdata:getInitialStatus(),
//   LiveFeedData:{},
//   LiveStatsData:{},
//   HourlyReportData:{},
//   ExportAllData:{},
//   totalRecords:0,
//   HourlytotalRecords:0,

//   filters: {
//     1: getInitialTabState(),
//     2: getInitialTabState(),
//     3: getInitialTabState(),
//     4: getInitialTabState(),
//     5: getInitialTabState(),
//   },
//   activeTab: 1,
//   tabNames: {
//     1: "Custom Report 1",
//     2: "Custom Report 2",
//     3: "Custom Report 3",
//     4: "Custom Report 4",
//     5: "Custom Report 5",
//   },
// };

// const filterSlice = createSlice({
//   name: "filter",
//   initialState,

//   reducers: {
//     setActiveTab: (state, action) => {
//       state.activeTab = action.payload;
//     },
//     setFilter: (state, action) => {
//       const { tabId, filterKey, filterValue } = action.payload;
//       if (state.filters[tabId]) {
//         state.filters[tabId][filterKey] = filterValue;
//         // console.log("Updated filter in Redux:", state.filters[tabId]);
//       }
//     },
//     setFilterOption: (state, action) => {
//       const { tabId, filterKey, filterOptions } = action.payload;
//       if (state.filters[tabId]) {
//         state.filters[tabId].filterOptions[filterKey] = filterOptions;
//         // console.log(
//         //   "Updated filter options in Redux:",
//         //   state.filters[tabId].filterOptions
//         // );
//       }
//     },
//     setSelectedOptions: (state, action) => {
//       const { tabId, selectedOptions } = action.payload;
//       if (state.filters[tabId]) {
//         state.filters[tabId].selectedOptions = selectedOptions;
//         // console.log(
//         //   "Updated selected options in Redux:",
//         //   state.filters[tabId].selectedOptions
//         // );
//       }
//     },

  
//     setFiltersForActiveTab: (state, action) => {
//       state.filters[state.activeTab] = action.payload;
//       // console.log(
//       //   "Updated filters for active tab in Redux:",
//       //   state.filters[state.activeTab]
//       // );
//     },
//     setFilterData: (state, action) => {
//       const { filters } = action.payload;
//       state.filters = filters;
//       // console.log("Updated filters data in Redux:", state.filters);
//     },
//     renameTab: (state, action) => {
//       const { tabId, newName } = action.payload;
//       if (state.tabNames[tabId]) {
//         state.tabNames[tabId] = newName; 
//       }
//     },
//     setTabNames: (state, action) => {
//       state.tabNames = action.payload; 
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(GetLiveReportFiltersAction.pending, (state) => {
//       state.Getfillterdata.isLoading = true;
//     });
//     builder.addCase(
//       GetLiveReportFiltersAction.fulfilled,
//       (state, action) => {
//         state.Getfillterdata.isLoading = false;
//         state.Getfillterdata.isSuccess = true;

//         const { response } = action.payload;
//         const updatedFilters = {};
//         const tabIds = [1, 2, 3, 4, 5];

//         tabIds.forEach((tabId) => {
//           const tabData = response.data?.[0]?.[`custom_report_${tabId}`] || {};
//           updatedFilters[tabId] = {
//             duration:
//               response.data?.[0]?.[`custom_report_${tabId}`]?.duration ||
//               getInitialTabState().duration,
//             indicator:
//               response.data?.[0]?.[`custom_report_${tabId}`]?.indicator ||
//               getInitialTabState().indicator,
//             calculation:
//               response.data?.[0]?.[`custom_report_${tabId}`]?.calculation ||
//               getInitialTabState().calculation,
//             filter:
//               response.data?.[0]?.[`custom_report_${tabId}`]?.filter ||
//               getInitialTabState().filter,
             
//             filterOptions: {
//               feed_provider:formatOpFeed(response.feed_provider) || [],
//               media_buyer: formatOpMedibuyer(response.media_buyer || []), 
//               channel: response.uniqueChannelNames || [],
//               channel_2: response.uniqueChannelNames2 || [],
//               channel_3: formatOpChennal3(response.uniqueChannelNames3) || [],
//             },

//             selectedOptions: tabData.selected_options || getInitialTabState().selectedOptions,
//             date_range: tabData.date_range || getCurrentDateRange(),
//           };
//           // console.log(`Tab ${tabId} date range:`, updatedFilters[tabId].date_range);
//         });
//         // console.log(' console.log(response.media_buyer || [])',formatOpMedibuyer(response.media_buyer  || []))



//         state.filters = updatedFilters;
//         state.tabNames = tabIds.reduce((acc, tabId) => {
//           acc[tabId] =
//             response.data?.[0]?.[`custom_report_${tabId}`]?.report_name ||
//             `Custom Report ${tabId}`;
//           return acc;
//         }, {});
//       }
//     );
//     builder.addCase(GetLiveReportFiltersAction.rejected, (state, action) => {
//       state.Getfillterdata.isLoading = false;
//       state.Getfillterdata.isError = true;
//       state.Getfillterdata.errorMsg = action.payload?.response;
//       state.Getfillterdata.errorResponse = action.payload;
//     });
//     // Custom Summary  report getdata 
//     builder.addCase(UpdateLiveCustomReportAction.pending, (state) => {
//         state.GetCustomLivedata.isLoading = true;
//       });
//       builder.addCase(UpdateLiveCustomReportAction.fulfilled, (state, action) => {
//         state.GetCustomLivedata.isLoading = false;
//         state.GetCustomLivedata.isSuccess = true;
//         state.GetCustomLivedata = action.payload;
//         // state.LiveFeedData = action.payload?.finalData;
//         // state.LiveStatsData = action.payload?.sumTotal;
//         state.totalRecords = action.payload?.total_length;
//         // state.ExportAllData = action.payload?.result;
//       });
//       builder.addCase(UpdateLiveCustomReportAction.rejected, (state, action) => {
//         state.GetCustomLivedata.isLoading = false;
//         state.GetCustomLivedata.isError = true;
//         state.GetCustomLivedata.errorMsg = action.payload?.response;
//         state.GetCustomLivedata.errorResponse = action.payload;
//       });

//       // GetHourlyLivedata
//       builder.addCase(GetHourlyLiveReportAction.pending, (state) => {
//         state.GetHourlyLivedata.isLoading = true;
//       });
//       builder.addCase(GetHourlyLiveReportAction.fulfilled, (state, action) => {
//         state.GetHourlyLivedata.isLoading = false;
//         state.GetHourlyLivedata.isSuccess = true;
//         state.GetHourlyLivedata = action.payload;
//         state.HourlyReportData = action.payload;
//         state.HourlytotalRecords = action.payload?.total_length;
  
//       });
//       builder.addCase(GetHourlyLiveReportAction.rejected, (state, action) => {
//         state.GetHourlyLivedata.isLoading = false;
//         state.GetHourlyLivedata.isError = true;
//         state.GetHourlyLivedata.errorMsg = action.payload?.response;
//         state.GetHourlyLivedata.errorResponse = action.payload;
//       });
//       //      // GetHourlyLivedata
//       //  builder.addCase(ExportSummaryReportAction.pending, (state) => {
//       //   state.GetExportdata.isLoading = true;
//       // });
//       // builder.addCase(ExportSummaryReportAction.fulfilled, (state, action) => {
//       //   state.GetExportdata.isLoading = false;
//       //   state.GetExportdata.isSuccess = true;
//       //   state.GetExportdata = action.payload;
//       //   state.ExportAllData = action.payload;
  
//       // });
//       // builder.addCase(ExportSummaryReportAction.rejected, (state, action) => {
//       //   state.GetExportdata.isLoading = false;
//       //   state.GetExportdata.isError = true;
//       //   state.GetExportdata.errorMsg = action.payload?.response;
//       //   state.GetExportdata.errorResponse = action.payload;
//       // });
//   },
// });

// export const {
//   setActiveTab,
//   setFilter,
//   setFilterOption,
//   setSelectedOptions,
//   setFiltersForActiveTab,
//   setFilterData,
//   renameTab,
//   setTabNames,
// } = filterSlice.actions;

// export default filterSlice.reducer;
