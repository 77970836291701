import { createSlice } from "@reduxjs/toolkit";
import {
  ManageGetproviderAction,
  ManageAddProviderAction,
  ManageDeleteProviderAction,
} from "./ManageSettingAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getManageProvider: getInitialStatus(),
  AddManageProvider: getInitialStatus(),
  DeleteManageProvider: getInitialStatus(),
  settingsData: [],
  totalCount:0
};

const Managesettingsslice = createSlice({
  name: "manageSetting",
  initialState,
  reducers: {
    resetSettings: (state) => {
      state.getManageProvider = getInitialStatus();
      state.AddManageProvider = getInitialStatus();
      state.DeleteManageProvider = getInitialStatus();
    },
    resetSettingsValue: (state) => {
      state.getManageProvider = getInitialStatus();
      state.AddManageProvider = getInitialStatus();
      state.DeleteManageProvider = getInitialStatus();
      state.totalCount = 0;
      state.settingsData = [];
      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ManageGetproviderAction.pending, (state) => {
      state.getManageProvider = getInitialStatus();
      state.getManageProvider.isLoading = true;
    });
    builder.addCase(ManageGetproviderAction.rejected, (state, action) => {
      state.getManageProvider = getInitialStatus();
      state.getManageProvider.isError = true;
      state.getManageProvider.errorResponse = action.payload;
    });
    builder.addCase(ManageGetproviderAction.fulfilled, (state, action) => {
      state.getManageProvider = getInitialStatus();
      state.getManageProvider.isSuccess = true;
      const {total_count, response, message } = action.payload || {};
      state.getManageProvider.successMsg = message;
      state.settingsData = response || [];
      state.totalCount = action.payload.total_count[0].count
      || 0; 
      // console.log('responce' ,action.payload);
    });

    builder.addCase(ManageAddProviderAction.pending, (state) => {
      state.AddManageProvider = getInitialStatus();
      state.AddManageProvider.isLoading = true;
    });
    builder.addCase(ManageAddProviderAction.rejected, (state, action) => {
      state.AddManageProvider = getInitialStatus();
      state.AddManageProvider.isLoading = false;
      state.AddManageProvider.isError = true;
      state.AddManageProvider.errorResponse = action.payload;
    });
    builder.addCase(ManageAddProviderAction.fulfilled, (state, action) => {
      state.AddManageProvider = getInitialStatus();
      state.AddManageProvider.isLoading = false;
      state.AddManageProvider.isSuccess = true;
      const { response, message } = action.payload || {};
      state.AddManageProvider.successMsg = message;
      state.settingsData = [...state.settingsData, response];
    });
    builder.addCase(ManageDeleteProviderAction.pending, (state) => {
      state.DeleteManageProvider = getInitialStatus();
      state.DeleteManageProvider.isLoading = true;
    });
    builder.addCase(ManageDeleteProviderAction.rejected, (state, action) => {
      state.DeleteManageProvider = getInitialStatus();
      state.DeleteManageProvider.isLoading = false;
      state.DeleteManageProvider.isError = true;
      state.DeleteManageProvider.errorResponse = action.payload;
    });
    builder.addCase(ManageDeleteProviderAction.fulfilled, (state, action) => {
      state.DeleteManageProvider = getInitialStatus();
      state.DeleteManageProvider.isLoading = false;
      state.DeleteManageProvider.isSuccess = true;
      const { response, message } = action.payload || {};
      state.DeleteManageProvider.successMsg = message;
    });
  },
});

export const { resetSettings, resetSettingsValue } =
  Managesettingsslice.actions;

export default Managesettingsslice.reducer;
