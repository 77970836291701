//   v1/summary-report

import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetLiveReportFiltersAction = createAsyncThunk(
  "v1/get-custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/get-custom-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const UpdateLiveCustomReportAction = createAsyncThunk(
  "v1/custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/custom-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const GetCustomLiveReportAction = createAsyncThunk(
  "v1/live-feed-report-data",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/live-feed-report-data',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const GetHourlyLiveReportAction = createAsyncThunk(
  "v1/hourly-data-live-feed",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/hourly-data-live-feed',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const ExportLiveReportAction = createAsyncThunk(
  "v1/request-data-excel-csv",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/request-data-excel-csv',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);