import { configureStore } from "@reduxjs/toolkit";

import UserAuthSlice from "./features/UserAuth/UserAuthSlice";
import ManageUserSlice from "./features/ManageUser/ManageUserSlice";
import ManagesettingSlice from "./features/Settings/ManagesettingSlice";
import ManageSiteSlice from "./features/Managesites/ManageSiteSlice";
import DashboardSlice from "./features/Dashboard/DashboardSlice";
import ReportSlice from "./features/Report/ReportSlice";
import FilterSlice from "./features/Filter/FilterSlice";
import SummaryReportSlice from "./features/SummaryReport/SummaryReportSlice";
import ActivityLogSlice from "./features/ActivityLog/ActivityLogSlice";
import ManageChannelSlice from "./features/ManageChannel/ManageChannelSlice";

    





export const store =configureStore({
    reducer:{
        userAuth:UserAuthSlice,
        manageUser:ManageUserSlice,
        manageSetting:ManagesettingSlice,
        manageSites:ManageSiteSlice,
        GetDashboard:DashboardSlice,
        GetReport:ReportSlice,
        GetSummary:SummaryReportSlice,
        ActivityLog:ActivityLogSlice,
        ManageChannel: ManageChannelSlice,
        // 
        filter:FilterSlice
        
    }
})


