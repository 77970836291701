import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetDashboardDetailsAction = createAsyncThunk(
  "v1/feedprovider-overview",
  async ({user_id,from_date, to_date }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/feedprovider-overview",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// v1/volume-report
export const GetVolumeReportDetailsAction = createAsyncThunk(
  "v1/volume-report",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/volume-report",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// v1/impression-trends

export const GetImpressionTrendsReportAction = createAsyncThunk(
  "v1/impression-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/impression-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// /v1/rpm-trends

export const GetRpmTrendsReportAction = createAsyncThunk(
  "/v1/rpm-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/rpm-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// rpc trends
export const GetRpCTrendsReportAction = createAsyncThunk(
  "v1/rpc-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/rpc-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// Cpc trends
export const GetCpCTrendsReportAction = createAsyncThunk(
  "v1/cpc-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/cpc-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// winner looser
export const GetWinnerLooserReportAction = createAsyncThunk(
  "/v1/winner-looser",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/winner-looser",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);



// campaign-count
export const GetCampaignCountAction = createAsyncThunk(
  "/campaign-count",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/campaign-count",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);



// account-wise-data
export const GetAccountWiseDataAction = createAsyncThunk(
  "/account-wise-data",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/account-wise-data",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);